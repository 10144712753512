import { PlatformEnvData, PlatformLogger } from '@wix/thunderbolt-symbols'
import { FallbackCorvidModel } from '@wix/editor-elements-corvid-utils'

import { IComponentSDKLoader, createComponentsRegistryPlatform } from './platform'
import { ComponentsRegistryError, ComponentsRegistryErrorTypes } from './errors'

export const getComponentsSDKLoader = async ({
	platformEnvData,
	logger,
}: {
	platformEnvData: PlatformEnvData
	logger: PlatformLogger
}): Promise<IComponentSDKLoader> => {
	const runtime = self.componentsRegistry ? self.componentsRegistry.runtime : null
	const libraries = runtime ? runtime.libraries : platformEnvData.componentsRegistry.librariesTopology

	const mode = platformEnvData.componentsRegistry.mode

	try {
		const componentsRegistryPlatform = await createComponentsRegistryPlatform({
			libraries,
			mode,
			loadFallbackSDKModule: () => FallbackCorvidModel.loadSDK() as any,
			runAndReport: (metric, fn) => {
				return logger.runAsyncAndReport(metric, fn)
			},
		})

		return componentsRegistryPlatform.getComponentsSDKsLoader()
	} catch (e) {
		return {
			sdkTypeToComponentTypes: {},
			loadComponentSdks: () =>
				Promise.reject(
					new ComponentsRegistryError(e.message, e.name, ComponentsRegistryErrorTypes.COMPONENT_LOADING_ERROR)
				),
		}
	}
}
