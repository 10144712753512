import _ from 'lodash'
import type { BiUtils, PlatformEnvData, ModelsAPI } from '@wix/thunderbolt-symbols'

export const PlatformBI = ({ biUtils, platformEnvData, modelsApi }: { biUtils: BiUtils; platformEnvData: PlatformEnvData; modelsApi: ModelsAPI }) => {
	const biLogger = biUtils.createBaseBiLoggerFactory().logger()

	return {
		reportWidgetsOnPage: () => {
			if (process.env.browser && platformEnvData.bi.pageData.pageNumber === 1 && !platformEnvData.bi.isPreview) {
				const widgetsList = _.mapValues(modelsApi.getApplications(), (controllers) => _.countBy(controllers, 'controllerType'))
				biLogger.report({ src: 72, evid: 520, endpoint: 'bpm', params: { widgetsList } })
			}
		},
	}
}
