import 'proxy-polyfill'
import './queueMicrotaskPollyfill'
import type { PlatformLogger } from '@wix/thunderbolt-symbols'
import { expose } from 'comlink/dist/esm/comlink.js' // eslint-disable-line no-restricted-syntax
import { createCommonWorker } from '../worker-commons/clientWorker'
import { siteAssetsClientWorkerAdapter } from './initSiteAssetsClient'
import { fetchModelsFactory } from '../fetchModelsFactory'
import type { PlatformClientWorkerAPI } from '../core/types'

const { initPlatformOnSite, runPlatformOnPage } = createCommonWorker()

const platformClientWorkerAPI: PlatformClientWorkerAPI = {
	initPlatformOnSite,
	runPlatformOnPage: (bootstrapData, invokeViewerHandler) => {
		function modelsProviderFactory(logger: PlatformLogger) {
			const siteAssetsClient = siteAssetsClientWorkerAdapter(bootstrapData.platformEnvData, logger)
			return fetchModelsFactory({ siteAssetsClient, bootstrapData, logger })
		}

		return runPlatformOnPage({
			bootstrapData,
			invokeViewerHandler,
			modelsProviderFactory,
		})
	},
}

expose(platformClientWorkerAPI)
